<template>
    <div class="container1150">
        <header><strong>EV-LB气泡式液位计</strong></header>
        <main>
            <p>
                EV-LB气泡式液位计是一款液位测量类设备，属于基于压力传感器和导气管的间接测量装置。主要用于水库、河流、湖泊、地下水、湿地、尾矿库、污水处理厂、水厂、排水管道等液位测量场合，可较好的应对淤积等使用环境，安装简便、易于操作，可检测液位、电池电压等多中参数，能够很好的满足用户对于产品高精度和高稳定性的需求。
            </p>
            <div class="img1"></div>
            <h2>产品特点</h2>
            <div class="img2"></div>
            <div class="img3"></div>
            <div class="img4"></div>
            <!-- <div class="img5"></div> -->
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            width: 100%;
            // width: 450px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/11-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/5-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/5-3.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img2 {
            width: 1000px;
            height: 300px;
            margin: 10px auto;
            background-image: url("/img/product/11-2.jpg");
            background-repeat: no-repeat;
            background-position-y: -1%;
            background-size: 100% 101%;
        }
        .img3 {
            width: 900px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/11-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img4 {
            width: 1100px;
            height: 350px;
            margin: 10px auto;
            background-image: url("/img/product/11-4.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img5 {
            width: 800px;
            height: 1050px;
            margin: 10px auto;
            background-image: url("/img/product/10-4.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>